import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App/';
import worker from './Utils/worker';

import 'bootstrap/dist/css/bootstrap.css';
import './index.css';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

worker();
