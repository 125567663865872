import React, { Component } from 'react';
import copy from 'copy-to-clipboard';

import Item from './item';
import Speech from '../../Components/SpeechBubble/speech';

import './index.css';

const DefaultState = {
  highlight: "none",
};

class Social extends Component {

  constructor(props) {
    super(props);

    this.state = DefaultState;
    this.items = [
      "email",
      "linkedin",
      "github",
      "appstore",
      //"twitter",
      "dribbble",
      //"youtube",
      //"redbubble",
      "steam"
    ]
    this.links = {
      dribbble: "https://dribbble.com/materik",
      appstore: "https://apps.apple.com/se/app/mealhive-recipe-keeper/id1559545243",
      homepage: "http://materikab.com",
      linkedin: "https://linkedin.com/in/materik",
      github: "https://github.com/materik",
      twitter: "https://twitter.com/thematerik",
      redbubble: "https://www.redbubble.com/people/thematerik", 
      youtube: "https://www.youtube.com/channel/UCYTSbdaROhKPelJEYFQhYnA",
      steam: "https://store.steampowered.com/app/1423420/Sublunar",
    }
  }

  onClick(identifier) {
    const link = this.links[identifier];
    if (link) { 
      window.open(link, '_blank'); 
    } else if (identifier === "email") {
      copy("mattias@materikab.com");
      Speech.speak([
        "You now have a copy of my email: ",
        "mattias@materikab.com"
      ])
    }
  }

  onMouseEnter(highlight) {
    switch (highlight) {
      case "email":
        Speech.speak([
          "📭 Click to copy my ", "email", "."
        ])
        break;
      case "appstore":
        Speech.speak([
          "📱 Visit my Recipe App ", "MealHive", " on ", "App Store", "."
        ])
        break;
      case "twitter":
        Speech.speak([
          "🦆 Follow me on ", "Twitter", "."
        ])
        break;
      case "linkedin":
        Speech.speak([
          "👨‍💼 Visit my ", "LinkedIn", " profile", "."
        ])
        break;
      case "dribbble":
        Speech.speak([
          "👨‍🎨 Follow me on ", "Dribbble", "."
        ])
        break;
      case "github":
        Speech.speak([
          "👨‍💻 Follow me on ", "GitHub", "."
        ])
        break;
      case "youtube":
        Speech.speak([
          "📹 Visit my ", "youtube", " channel where I upload my ", "game devlogs", "."
        ])
        break;
      case "redbubble":
        Speech.speak([
          "🎨 Visit my ", "Redbubble", " store where I sell my art."
        ])
        break;
      case "steam":
        Speech.speak([
          "🎮 Visit my Indie Game ", "Sublunar", " on ", "Steam", "."
        ])
        break;
      default:
        break;
    }

    this.setState({
      highlight,
    });
  }

  onMouseLeave() {
    // Do nothing...
  }

  render() {
    var social = []
    for (var i in this.items) {
      var identifier = this.items[i];
      social.push(
        <Item 
          identifier={identifier}
          key={identifier}
          onClick={ (e) => this.onClick(e) }
          onMouseEnter={ (e) => this.onMouseEnter(e) }
          onMouseLeave={ (e) => this.onMouseLeave() } />
      )
    }

    return (
      <div id="social-container">
        <div id="social" className={"highlight-" + this.state.highlight}>
          <ul>{social}</ul>
        </div>
      </div>
    );
  }

}

export default Social;
