import React, { Component } from 'react';

import Avatar from './avatar';
import Social from '../../Components/Social';
import Speech from '../../Components/SpeechBubble/speech';

import './index.css';

var DefaultState = {
  showAllAvatars: false,
};

class Profile extends Component {

  constructor(props) {
    super(props);

    this.state = DefaultState
  }

  onMouseEnter(highlight) {
    switch (highlight) {
      case "materik":
        this.setState({showAllAvatars: true});
        break;
      case "mattias eriksson":
        Speech.speak([
          "👫 My name before I got married."
        ])
        break;
      case "japanese":
        Speech.speak([
          "🇯🇵 I study ", "Japanese", "."
        ])
        break;
      default:
        break;
    }
  }

  onMouseLeave() {
    this.setState(DefaultState);

    Speech.speak();
  }

  render() {
    return (
      <div id="profile">
        <Avatar on={this.state.showAllAvatars} />
        <h1
          className="past"
          onMouseEnter={ (e) => this.onMouseEnter("mattias eriksson") }
          onMouseLeave={ (e) => this.onMouseLeave() }>
          Mattias Eriksson
        </h1>
        <h1 className="name">Mattias Farnemyhr</h1>
        <h1 
          className="nickname"
          onMouseEnter={ (e) => this.onMouseEnter("materik") }
          onMouseLeave={ (e) => this.onMouseLeave() }>
          Materik
        </h1>
        <h1 className="nickname"
          onMouseEnter={ (e) => this.onMouseEnter("japanese") }
          onMouseLeave={ (e) => this.onMouseLeave() }>
          マテｨアス
        </h1>
        <Social />
      </div>
    );
  }

}

export default Profile;
