import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import Item from './item';

import './index.css';

class Menu extends Component {

  constructor(props) {
    super(props);

    this.order = {

      mobile: [
        "ios-developer",
        "backend-developer",
        "married",
        "father",
        "cat-owner",
        "movie-lover",
        "runner",
        "vegetarian",
        "residence",
        "scrum-master",
        "game-developer",
        "ui-designer",
        "web-developer",
        "tester",
        "physicist",
      ],

      desktop: [
        "physicist",
        "tester",
        "web-developer",
        "ui-designer",
        "game-developer",
        "scrum-master",
        "backend-developer",
        "ios-developer",
        "married",
        "father",
        "cat-owner",
        "movie-lover",
        "runner",
        "vegetarian",
        "residence",
      ]

    };

    this.items = {
      "physicist": {
        identifier: "physicist",
        title: "Physicist",
        type: "past",
        properties: "hoverable",
      },
      "tester": {
        identifier: "tester",
        title: "Tester",
        type: "past",
        properties: "hoverable",
      },
      "backend-developer": {
        identifier: "backend-developer",
        title: "Backend Developer",
        type: "current",
        properties: "hoverable",
      },
      "web-developer": {
        identifier: "web-developer",
        title: "Frontend Developer",
        type: "past",
        properties: "hoverable",
      },
      "ios-developer": {
        identifier: "ios-developer",
        title: "iOS Developer",
        type: "current",
        properties: "hoverable",
      },
      "ui-designer": {
        identifier: "ui-designer",
        title: "Product Designer",
        type: "past",
        properties: "hoverable",
      },
      "scrum-master": {
        identifier: "scrum-master",
        title: "Product Owner",
        type: "past",
        properties: "hoverable",
      },
      "game-developer": {
        identifier: "game-developer",
        title: "Game Developer",
        type: "past",
        properties: "hoverable clickable",
        onClick: (e) => this.gotoSteam(),
      },
      "movie-lover": {
        identifier: "movie-lover",
        title: "Movie Lover",
        type: "interest",
        properties: "hoverable clickable",
        onClick: (e) => this.gotoLetterboxd(),
      },
      "runner": {
        identifier: "runner",
        title: "Runner",
        type: "interest",
        properties: "hoverable clickable",
        onClick: (e) => this.gotoStrava(),
      },
      "vegetarian": {
        identifier: "vegetarian",
        title: "Vegetarian",
        type: "interest",
        properties: "hoverable",
      },
      "cat-owner": {
        identifier: "cat-owner",
        title: "Cat Owner",
        type: "interest",
        properties: "hoverable",
      },
      "traveller": {
        identifier: "traveller",
        title: "Globetrotter",
        type: "interest",
        properties: "hoverable",
      },
      "residence": {
        identifier: "residence",
        title: "Swede",
        type: "interest",
        properties: "hoverable clickable",
        onClick: (e) => this.gotoResidence(),
      },
      "father": {
        identifier: "father",
        title: "Father",
        type: "interest",
        properties: "hoverable",
      },
      "married": {
        identifier: "married",
        title: "Husband",
        type: "interest",
        properties: "hoverable",
      }
    };
  }

  componentDidMount() {
    this.onResize();
    
    window.addEventListener('resize', () => this.onResize() );
  }

  gotoLetterboxd() {
    window.open("https://letterboxd.com/materik", '_blank');
  }

  gotoStrava() {
    window.open("https://www.strava.com/athletes/1602663", '_blank');
  }

  gotoResidence() {
    window.open("https://www.google.se/maps/place/Sommenforum/@58.0392927,14.9858119,17z/data=!3m1!4b1!4m6!3m5!1s0x465a29bc591cfd85:0x9f8e3fd986c308cb!8m2!3d58.0392899!4d14.9883922!16s%2Fg%2F11vq2r277f?entry=ttu&g_ep=EgoyMDI0MTAyMy4wIKXMDSoASAFQAw%3D%3D", '_blank');
  }

  gotoSteam() {
    window.open("https://store.steampowered.com/app/1423420/Sublunar", '_blank'); 
  }

  onResize() {
    if (this.props.getPositionBottom !== undefined) {
      this.props.getPositionBottom(ReactDOM.findDOMNode(this).getBoundingClientRect().bottom)
    }

    if (this.props.getPositionLeft !== undefined) {
      this.props.getPositionLeft(ReactDOM.findDOMNode(this).getBoundingClientRect().left - 30)
    }

    if (this.props.getPositionTop !== undefined) {
      this.props.getPositionTop(ReactDOM.findDOMNode(this).getBoundingClientRect().top)
    }
  }

  getItems(key) {
    var self = this;
    var order = this.order[key];
    return order.map(function(key) {
      var item = self.items[key];
      return (
        <Item
          key={key}
          identifier={item.identifier}
          title={item.title}
          type={item.type}
          properties={item.properties}
          onClick={ item.onClick }
          onMouseEnter={ (e) => self.props.onMouseEnter(e) }
          onMouseLeave={ (e) => self.props.onMouseLeave(e) }
          onMouseScroll={ (e) => self.props.onMouseScroll(e) } />
      );
    });
  }

  render() {
    return (
      <div id="menu">
        <div className="hide-desktop">{this.getItems("mobile")}</div>
        <div className="show-desktop">{this.getItems("desktop")}</div>
      </div>
    );
  }
}

Menu.propTypes = {
  getPositionBottom: PropTypes.func,
  getPositionLeft: PropTypes.func,
  getPositionTop: PropTypes.func,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  onMouseScroll: PropTypes.func.isRequired,
}

export default Menu;
