import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Letterboxd from './letterboxd';
import Speech from '../../Components/SpeechBubble/speech';
import Strava from './strava';

import './index.css';

class MenuItem extends Component {

  constructor(props) {
    super(props);

    this.movie = undefined;
    this.run = undefined;

    this.getLastSeenMovie()
    this.getLastRun()
  }

  onMouseEnter() {
    if (this.props.onMouseEnter !== undefined) {
      this.props.onMouseEnter(this.props.identifier)
    }

    switch (this.props.identifier) {
      case "movie-lover":
        this.getLastSeenMovie()
        Speech.speak(this.movie.speech)
        break;
      case "runner":
        this.getLastRun()
        Speech.speak(this.run.speech)
        break;
      case "cat-owner":
        Speech.speak([
          "😻 We have a Siberian cat named ",
          "Totoro",
          ".",
        ]);
        break;
      case "traveller":
        Speech.speak([
          "🇰🇷🇯🇵 In ", "May 2019", " we went to ",
          "South Korea", " and ", "Japan", 
          ", two amazing places!",
        ]);
        break;
      case "vegetarian":
        Speech.speak([
          "🥦 Powered by ",
          "Plants",
          ".",
        ]);
        break;
      case "residence":
        Speech.speak([
          "🇸🇪 I reside in ",
          "Tranås, Sweden",
          ".",
        ]);
        break;
      case "father":
        Speech.speak([
          "🧑‍🧑‍🧒‍🧒 I have two sons: ",
          "Milo",
          ", " 
          + moment("20200705", "YYYYMMDD").fromNow().replace(" ago", "")
          + " old, and ",
          "Elton",
          ", " 
          + moment("20230409", "YYYYMMDD").fromNow().replace(" ago", "")
          + " old."
        ]);
        break;
      case "married":
        Speech.speak([
          "👰 I got married to my ",
          "Julia",
          " on ",
          "March 2nd, 2019",
          "."
        ]);
        break;
      default:
        break;
    }
  }

  onMouseLeave() {
    if (this.props.onMouseLeave !== undefined) {
      this.props.onMouseLeave()
    }

    // NOTE(materik): remove the comment if you want the speech to reset on mouse leave
    // Speech.speak()
  }

  getLastSeenMovie() {
    if (this.props.identifier === "movie-lover") {
      Letterboxd.getLastSeenMovie( (movie) => {
        this.movie = movie;
      })
    }
  }

  getLastRun() {
    if (this.props.identifier === "runner") {
      Strava.getLastRun( (run) => {
        this.run = run;
      })
    }
  }

  render() {
    var className = this.props.identifier + " " + this.props.type + " " + this.props.properties;
    return (
      <div className="menu-item-container">
        <h1 
          className={className}
          onClick={ (e) => this.props.onClick && this.props.onClick() }
          onMouseEnter={ (e) => this.onMouseEnter() }
          onMouseLeave={ (e) => this.onMouseLeave() }
          onWheel={ (e) => this.props.onMouseScroll && this.props.onMouseScroll(e.deltaY) }>
          {this.props.title}
        </h1>
      </div>
    );
  }

}

MenuItem.propTypes = {
  identifier: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  properties: PropTypes.string,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onMouseScroll: PropTypes.func,
}

export default MenuItem;
